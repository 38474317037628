import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import teams from "../data/teams.json";
import { useEffect } from "react";

interface Props {
  name: string;
  label: string;
}

function TeamSelect({ name, label }: Props) {
  const { control, getValues, setValue } = useFormContext();

  // Watch the sender_team_id value
  const senderTeamId = useWatch({
    control,
    name: "sender_team_id",
  });

  // Use effect to reset receiver_team_id when sender_team_id changes
  useEffect(() => {
    if (name === "receiver_team_id") {
      setValue("receiver_team_id", ""); // Reset receiver_team_id
    }
  }, [senderTeamId, name, setValue]);

  const isDisabled = (team_id: number) => {
    if (name === "receiver_team_id") {
      return getValues("sender_team_id") === team_id;
    }
    return false;
  };

  return (
    <FormControl sx={{ mb: name === "sender_team_id" ? 2 : 0 }} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{ required: "Lag är obligatoriskt" }}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select {...field} label={label} error={!!error}>
              {teams.map((team) => (
                <MenuItem
                  key={team.team_id}
                  value={team.team_id}
                  disabled={isDisabled(team.team_id)}
                >
                  {team.team_name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!error}>
              {name === "sender_team_id"
                ? "Välj vinnande lag"
                : "Välj förlorande lag"}
            </FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
}

export default TeamSelect;