import { Box } from "@mui/material";

const IntegrityTerms = () => (
  <Box p={2} pt={10}>
    <h1>Integritetspolicy för Finten.se</h1>

    <h2>1. Inledning</h2>

    <p>
      1.1. Denna integritetspolicy beskriver hur S. Ring Development AB, orgnr:
      559477-1700, hanterar och skyddar dina personuppgifter när du använder
      våra tjänster på Finten.se.
    </p>
    <p>
      1.2. Genom att använda Finten.se samtycker du till behandlingen av dina
      personuppgifter i enlighet med denna policy.
    </p>

    <h2>2. Insamling av personuppgifter</h2>

    <p>
      2.1. Vi samlar endast in de personuppgifter som är absolut nödvändiga för
      att tillhandahålla våra tjänster. Detta inkluderar:
    </p>
    <ul>
      <li>Telefonnummer till mottagaren av SMS:en.</li>
    </ul>
    <p>
      2.2. Vi samlar inte in eller lagrar e-postadresser eller andra
      personuppgifter.
    </p>

    <h2>3. Användning av personuppgifter</h2>

    <p>
      3.1. De insamlade personuppgifterna används enbart för att leverera de
      beställda SMS:en med historiska matchresultat från Allsvenskan.
    </p>
    <p>
      3.2. Vi använder inte dina personuppgifter för marknadsföringsändamål
      eller delar dem med tredje part, förutom när det krävs enligt lag.
    </p>

    <h2>4. Lagring av personuppgifter</h2>

    <p>
      4.1. Personuppgifterna lagras endast så länge som det är nödvändigt för
      att tillhandahålla tjänsten och uppfylla våra avtalsenliga och juridiska
      skyldigheter.
    </p>
    <p>
      4.2. När personuppgifterna inte längre behövs raderas de på ett säkert
      sätt.
    </p>

    <h2>5. Skydd av personuppgifter</h2>

    <p>
      5.1. Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att
      skydda personuppgifterna mot obehörig åtkomst, förlust, ändring eller
      förstöring.
    </p>
    <p>5.2. Endast behörig personal har tillgång till personuppgifterna.</p>

    <h2>6. Dina rättigheter</h2>

    <p>
      6.1. Du har rätt att begära information om de personuppgifter vi har
      lagrat om dig och få en kopia av dessa uppgifter.
    </p>
    <p>
      6.2. Du har rätt att begära rättelse av felaktiga personuppgifter samt
      radering av dina personuppgifter under vissa förutsättningar.
    </p>
    <p>
      6.3. För att utöva dina rättigheter, vänligen kontakta oss via
      kontaktuppgifterna nedan.
    </p>

    <h2>7. Ändringar i integritetspolicyn</h2>

    <p>
      7.1. Vi förbehåller oss rätten att när som helst ändra denna
      integritetspolicy. Eventuella ändringar kommer att publiceras på vår
      webbplats Finten.se.
    </p>
    <p>
      7.2. Vi rekommenderar att du regelbundet kontrollerar denna policy för att
      hålla dig informerad om hur vi skyddar dina personuppgifter.
    </p>

    <h2>8. Kontaktinformation</h2>

    <p>
      För frågor om denna integritetspolicy eller behandling av dina
      personuppgifter, vänligen kontakta oss via:
    </p>

    <ul>
      <li>E-post: kontakt@finten.se</li>
      <li>
        Finten.se
        <br />
        c/o Danex Flaggbutik
        <br />
        Sturegatan 55
        <br />
        50342 Borås
      </li>
      <li>S. Ring Development AB, orgnr: 559477-1700</li>
    </ul>

    <p>
      Genom att använda Finten.se accepterar du denna integritetspolicy och vår
      hantering av dina personuppgifter.
    </p>
  </Box>
);

export default IntegrityTerms;
