export const frequencyValues = [
    { value: 15, label: "varje kvart" },
    { value: 30, label: "varje halvtimme" },
    { value: 60, label: "varje timme" },
    { value: 120, label: "varannan timme" },
    { value: 360, label: "var 6:e timme" },
    { value: 720, label: "var 12:e timme" },
    { value: 1440, label: "varje dag" },
    { value: 2880, label: "varannan dag" },
    { value: 4320, label: "var tredje dag" },
    { value: 10080, label: "varje vecka" }
]