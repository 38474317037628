import { Box, Link, List, ListItem } from "@mui/material";

const Footer = () => (
  <Box
    mt={10}
    py={5}
    px={2}
    display="flex"
    justifyContent="center"
    fontSize={12}
    color='#fff'
    component="footer"
    bgcolor="primary.main"
  >
    <Box>
        <List>
            <ListItem>kontakt@finten.se</ListItem>
            <ListItem>Finten.se är en del av S. Ring Development AB, orgnr: 559477-1700</ListItem>
        </List>
    </Box>
    <List>
      <ListItem>
        <Link color="#fff" href="/kopvillkor">
          Köp- och leveransvillkor
        </Link>
      </ListItem>
      <ListItem>
        <Link color="#fff" href="/tos">
          Användarvillkor
        </Link>
      </ListItem>
      <ListItem>
        <Link color="#fff" href="/privacy">
          Integritetspolicy
        </Link>
      </ListItem>
    </List>
  </Box>
);

export default Footer;
