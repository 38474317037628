import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFortnox } from "../../context/FortnoxContext";
import { useAuth } from "../../context/AuthContext";

const Callback: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setToken } = useFortnox();
  const { setIsAuthenticated } = useAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    const fetchAccessTokenFromBackend = async (code: string) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/admin/fortnox/get-token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ code }),
            credentials: 'include', // Lägg till detta för att inkludera cookies
          }
        );
  
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        const data = await response.json();
        console.log("Access token:", data.access_token);
        setToken(data.access_token);
        setIsAuthenticated(true);
        navigate("/admin"); // Redirect till /admin
      } catch (error) {
        console.error("Error fetching access token from backend:", error);
      }
    };

    if (code) {
      // Skicka koden till din backend för att hämta access token
      fetchAccessTokenFromBackend(code);
    }
  }, [location, setIsAuthenticated, setToken, navigate]);

  return (
    <div>
      <h1>Callback</h1>
      <p>Processing...</p>
    </div>
  );
};

export default Callback;