import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Container,
  Typography,
  Button
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PaidIcon from '@mui/icons-material/Paid';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import BookIcon from '@mui/icons-material/Book'; // Importera ny ikon för "bookkept"
import { useFortnox } from '../../context/FortnoxContext';

interface Order {
  id: number;
  phone_number: string;
  total_messages: number;
  interval_minutes: number;
  sender_team_id: number;
  sender_team_name: string;
  receiver_team_id: number;
  receiver_team_name: string;
  scheduled: boolean;
  swish_id: string;
  status: string;  // 'paid', 'pending' eller 'bookkept'
  placed_at: string;
  amount: number;
  discount: number;
}

type OrderBy = keyof Order;

const OrdersTable: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [orderBy, setOrderBy] = useState<OrderBy>('id');
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const { token, setToken } = useFortnox(); // Hämta token och refreshToken från FortnoxContext

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/orders`, { credentials: 'include' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setOrders(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleSortRequest = (property: OrderBy) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderBy(property);
    setOrderDirection(isAsc ? 'desc' : 'asc');
  };

  const handleBookkeeping = async (orderId: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/fortnox/${orderId}/bookkeep`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Lägg till Fortnox-token i Authorization-headern
        },
        credentials: 'include'
      });

      if (response.status === 401) {
        // Tokenen har gått ut, förnya tokenen
        setToken('');

        // Försök igen med den nya tokenen
        const retryResponse = await fetch(`${process.env.REACT_APP_API_URL}/admin/fortnox/${orderId}/bookkeep`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${''}`, // Använd den nya tokenen
          },
          credentials: 'include'
        });

        if (!retryResponse.ok) {
          throw new Error('Network response was not ok');
        }
      } else if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Uppdatera orderstatus eller refetch ordrar efter bokföring
      fetchOrders();
    } catch (error) {
      console.error('Error bookkeeping order:', error);
    }
  };

  const sortedOrders = orders.slice().sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return orderDirection === 'asc' ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return orderDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Orderhantering
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {[
                { id: 'id', label: 'ID' },
                { id: 'phone_number', label: 'Telefonnummer' },
                { id: 'total_messages', label: 'Antal Meddelanden' },
                { id: 'interval_minutes', label: 'Intervall (minuter)' },
                { id: 'sender_team_name', label: 'Avsändarens Lag' },
                { id: 'receiver_team_name', label: 'Mottagarens Lag' },
                { id: 'scheduled', label: 'Schemalagt' },
                { id: 'swish_id', label: 'Swish ID' },
                { id: 'status', label: 'Status' },
                { id: 'placed_at', label: 'Beställd Tid' },
                { id: 'amount', label: 'Belopp' },
                { id: 'discount', label: 'Rabatt' },
                { id: 'actions', label: 'Åtgärder' }  // Ny kolumn för åtgärder
              ].map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? orderDirection : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? orderDirection : 'asc'}
                    onClick={() => handleSortRequest(headCell.id as OrderBy)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedOrders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>{order.id}</TableCell>
                <TableCell>{order.phone_number}</TableCell>
                <TableCell>{order.total_messages}</TableCell>
                <TableCell>{order.interval_minutes}</TableCell>
                <TableCell>{order.sender_team_name}</TableCell>
                <TableCell>{order.receiver_team_name}</TableCell>
                <TableCell>
                  {order.scheduled ? (
                    <CheckCircleIcon style={{ color: 'green' }} />
                  ) : (
                    <CancelIcon style={{ color: 'red' }} />
                  )}
                </TableCell>
                <TableCell>{order.swish_id}</TableCell>
                <TableCell>
                  {order.status === 'bookkept' ? (
                    <BookIcon style={{ color: 'blue' }} />
                  ) : order.status === 'paid' ? (
                    <PaidIcon style={{ color: 'green' }} />
                  ) : (
                    <HourglassEmptyIcon style={{ color: 'orange' }} />
                  )}
                </TableCell>
                <TableCell>{new Date(order.placed_at).toLocaleString()}</TableCell>
                <TableCell>{order.amount}</TableCell>
                <TableCell>{order.discount}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={order.status !== 'paid'}
                    onClick={() => handleBookkeeping(order.id)}
                  >
                    Bokför
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default OrdersTable;