import React from 'react';
import Auth from './FortnoxAuth';
import OrdersTable from './OrdersTable';

const Admin: React.FC = () => {
  return (
    <div>
      <h1>Admin Panel</h1>
      <p>Här kan du hantera ordrar och utföra andra administrativa uppgifter.</p>
      <Auth />
      <OrdersTable />
    </div>
  );
};

export default Admin;