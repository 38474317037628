import { AppBar, Dialog, IconButton, Link, Toolbar } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import UserTerms from "./terms/UserTerms";
import PurchaseTerms from "./terms/PurchaseTerms";
import IntegrityTerms from "./terms/IntegrityTerms";

export enum TermsEnum {
  USER,
  PURCHASE,
  PRIVACY,
}
interface Props {
  terms: TermsEnum;
  initialOpen?: boolean;
}

const Terms = ({ terms, initialOpen }: Props) => {
  const [open, setOpen] = useState(initialOpen ?? false);
  const navigate = useNavigate();

  const getTitle = () => {
    switch (terms) {
      case TermsEnum.USER:
        return "användarvillkor";
      case TermsEnum.PURCHASE:
        return "köp- och leveransvillkor";
      case TermsEnum.PRIVACY:
        return "integritetspolicy";
      default:
        return null;
    }
  };

  const getComponent = () => {
    switch (terms) {
      case TermsEnum.USER:
        return UserTerms;
      case TermsEnum.PURCHASE:
        return PurchaseTerms;
      case TermsEnum.PRIVACY:
        return IntegrityTerms;
      default:
        return null;
    }
  };

  const Component = getComponent();

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };
  return (
    <>
      <Link variant="caption" onClick={() => setOpen(true)}>{getTitle()}</Link>
      <Dialog open={open} fullScreen onClose={handleClose}>
        <AppBar sx={{ position: "fixed" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {Component ? <Component /> : null}
      </Dialog>
    </>
  );
};

export default Terms;
