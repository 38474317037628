import { Box, Link } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const Confirmation = () => {
  const [searchParams] = useSearchParams();

  const downloadReceipt = async () => {
    const swishRef = searchParams.get("swish_ref");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/receipt/${swishRef}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Kvitto Finten.se.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h1 style={{ textAlign: "center" }}>
        Tack för din order. Dina meddelanden kommer snart börja skickas. Första meddelandet skickas om cirka 15 minuter
      </h1>
      <Link onClick={downloadReceipt}>Kvitto</Link>
    </Box>
  );
};

export default Confirmation;
