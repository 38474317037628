import { FormHelperText, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  name: string;
}

function PhoneInput({ name }: Props) {
  const { control } = useFormContext();

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = event.target.value;
    // Remove all non-digit characters from the input
    let numbers = value.replace(/\D/g, "");
    // Limit the length to 10 digits
    numbers = numbers.slice(0, 10);

    // Formatting as XXX-XXX XXXX
    let formatted = numbers;
    if (numbers.length > 3) {
      formatted = `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
    }
    if (numbers.length > 6) {
      formatted = `${numbers.slice(0, 3)}-${numbers.slice(
        3,
        6
      )} ${numbers.slice(6)}`;
    }

    return formatted;
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{ required: "Telefonnummer är obligatoriskt" }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            inputMode="tel"
            label="Telefonnummer"
            variant="outlined"
            error={!!error}
            fullWidth
            margin="normal"
            helperText="Mottagarens telefonnummer"
            onChange={(e) => field.onChange(handleInputChange(e))}
          />
        )}
      />
      <FormHelperText>
        Fyll i mottagarens telefonnummer
      </FormHelperText>
    </>
  );
}

export default PhoneInput;
