import { Box } from "@mui/material";

const Header = () => {
  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(8px)",
          boxShadow: "0px 1px 8px 0px rgba(0,0,0,0.28)",
        }}
        position="fixed"
        width="100%"
        padding={2}
        bgcolor="rgba(255,255,255,0.8)"
        zIndex={3}
      >
        <a href="/">
          <img
            style={{ width: 30, height: "auto" }}
            src="/logo.png"
            alt="Finten.se"
          ></img>
        </a>
      </Box>
      <Box height={90} />
    </>
  );
};

export default Header;
