import React, { useState, useEffect } from "react";
import "./Conversation.css";

const predefinedMessages = [
    "[Datum] och [Lag X] hade ingen chans mot [Lag Y], som vann med [Resultat]. Skickar bara en vänlig påminnelse ;)",
    "Minns du när [Lag X] blev helt överkörda av [Lag Y] med [Resultat] den [Datum]? Haha, vilken match det var!",
    "En liten påminnelse från [Datum] – [Lag Y] mot [Lag X]: [Resultat]. Ingen stor grej, bara historisk dominans.",
    "Vem kunde glömma [Datum]? Inte [Lag X] i alla fall, som förlorade mot [Lag Y] med [Resultat]. Bättre lycka nästa gång... kanske.",
    "Det där spelet den [Datum]? När [Lag Y] lät [Lag X] smaka på nederlag med [Resultat]. Oj, vilken dag!"
  ];

const Conversation = () => {
  const [messages, setMessages] = useState<string[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    if (messageIndex < predefinedMessages.length) {
      const pauseTimer = setTimeout(() => {
        setIsTyping(true);
        const typingTimer = setTimeout(() => {
          setMessages([...messages, predefinedMessages[messageIndex]]);
          setIsTyping(false);
          setMessageIndex(messageIndex + 1);
        }, 1200); // Simulate typing for 3 seconds

        return () => clearTimeout(typingTimer);
      }, 650); // 2 seconds pause before typing starts, increased by 1 second after each message

      return () => clearTimeout(pauseTimer);
    }
  }, [messages, messageIndex]);

  return (
    <div className="conversation">
      {messages.map((message, index) => (
        <div key={index} className="message">
          {message}
        </div>
      ))}
      {isTyping && (
        <div className="typing-indicator">
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
    </div>
  );
};

export default Conversation;
