import { Box } from "@mui/material";

const UserTerms = () => (
  <Box p={2} pt={10}>
    <h1>Användarvillkor Finten.se</h1>
    <p>
      Genom att använda vår tjänst godkänner du följande användarvillkor.
      Vänligen läs dem noggrant.
    </p>
    <p>
      Kunden förbinder sig att ej nyttja tjänsten på ett sätt som ej är
      förenligt med vid varje tid gällande lag och rätt, samt förbinder sig
      Kunden att utan begränsning hålla Finten.se skadeslöst för varje
      eventuellt anspråk mot Finten.se från tredje man grundat på Kundens
      nyttjande av tjänsten.
    </p>

    <h2>1. Tjänstens beskrivning</h2>
    <p>
      Finten.se erbjuder en unik tjänst där användare kan "pranka" sina vänner
      genom att skicka SMS med resultatet av fotbollsmatcher där användarens
      favoritlag har vunnit mot vännens favoritlag. Användaren kan välja antal
      SMS som ska skickas samt hur ofta dessa SMS skickas.
    </p>

    <h2>2. Trakasseripolicy</h2>
    <p>
      Tjänsten får inte användas i syfte att trakassera eller på något sätt
      orsaka obehag för mottagaren. Vi förbehåller oss rätten att avsluta eller
      begränsa användares tillgång till tjänsten om vi anser att denna policy
      inte följs.
    </p>

    <h2>3. Användarens ansvar</h2>
    <p>
      Som användare ansvarar du för att mobilnumret som anges tillhör en person
      som rimligen kan uppskatta skämtet, exempelvis en anhörig, vän eller
      bekant. Du bör inte använda tjänsten mot personer som du inte har en
      befintlig positiv relation med.
    </p>

    <h2>4. Ändringar i tjänsten och användarvillkor</h2>
    <p>
      Vi förbehåller oss rätten att när som helst ändra eller avsluta tjänsten
      samt dessa användarvillkor. Ändringar träder i kraft omedelbart efter att
      de publicerats på vår webbplats.
    </p>

    <h2>5. Integritet och personuppgifter</h2>
    <p>
      Vi säljer inte dina personuppgifter. Uppgifter som samlas in används
      enbart för att fullgöra vår leverans av tjänsten och för bokföring. För
      mer information, se vår integritetspolicy.
    </p>

    <h2>6. Begränsning av ansvar</h2>
    <p>
      Finten.se ansvarar inte för direkta eller indirekta skador som orsakats av
      användning eller missbruk av tjänsten, inklusive men inte begränsat till
      felaktigheter i levererade resultat.
    </p>

    <h2>7. Avslutning av tjänst</h2>
    <p>
      Vi förbehåller oss rätten att utan förvarning avsluta leveransen och
      stänga ner ett konto om vi anser att dessa villkor inte följs.
    </p>

    <h2>8. Jurisdiktion och tvistlösning</h2>
    <p>
      Tvister som uppstår i samband med dessa användarvillkor ska lösas i
      enlighet med svensk lag och genom svenska domstolar.
    </p>

    <h2>9. Återbetalningspolicy</h2>
    <p>
      Om mottagaren väljer att avstå från leveransen av tjänsten är vi inte
      skyldiga att erbjuda någon återbetalning.
    </p>

    <h2>10. Sekventiell Leverans</h2>
    <p>
      För att säkerställa en positiv användarupplevelse och undvika spam,
      hanterar Finten.se inkommande beställningar sekventiellt. Det innebär att
      om det finns en pågående leverans till ett specifikt nummer, kommer
      efterföljande beställningar att schemaläggas för leverans efter den
      pågående beställningens avslut.
    </p>

    <p>
      Genom att använda Finten.se, bekräftar du att du har läst, förstått och
      godkänt dessa användarvillkor.
    </p>

    <h2>11. Ansvar för matchdata</h2>
    <p>
      Finten.se ansvarar inte för antalet meddelanden som levereras baserat på
      tillgänglig matchdata. Om en användare beställer en specifik mängd
      resultatmeddelanden, exempelvis 20, och de inblandade lagen endast har
      mötts ett begränsat antal gånger som är färre än det beställda antalet,
      till exempel 5 gånger, kommer endast meddelanden baserade på de
      tillgängliga matchresultaten att levereras. Detta innebär att i detta
      exempel enbart 5 meddelanden kommer att skickas ut. Vi strävar efter att
      alltid tillhandahålla korrekt och uppdaterad matchdata men är beroende av
      externa källor för denna information.
    </p>
    <h2>12. Kontaktinformation</h2>

    <p>För frågor eller klagomål, vänligen kontakta oss via:</p>

    <ul>
      <li>E-post: kontakt@finten.se</li>
      <li>
        Finten.se
        <br />
        c/o Danex Flaggbutik
        <br />
        Sturegatan 55
        <br />
        50342 Borås
      </li>
      <li>S. Ring Development AB, orgnr: 559477-1700</li>
    </ul>
  </Box>
);

export default UserTerms;
