import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Radio,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { frequencyValues } from "../consts/form";

interface Props {
  id: number;
  title: string;
  price: number;
  color: string;
}

function SelectableCard({ id, title, price, color }: Props) {
  const { setValue, watch, clearErrors, formState: { errors }  } = useFormContext();
  const [frequency, setFrequency] = useState<string>();
  const [error, setError] = useState(false);
  const selectedCard = watch("number_of_messages");
  const frequencyWatch = watch("frequency");

  const handleClick = () => {
    setValue("number_of_messages", id);
    clearErrors("number_of_messages");
  };

  useEffect(() => {
    const label = frequencyValues.find(
      ({ value }) => value === frequencyWatch
    )?.label;
    setFrequency(label ?? "varje halvtimme");
  }, [frequencyWatch]);

  useEffect(() => {
    setError(!!errors?.number_of_messages)
  }, [errors?.number_of_messages])

  return (
    <Card
      onClick={handleClick}
      sx={{
        boxShadow:
          selectedCard === id
            ? "0px 0px 5px 1px rgb(25 118 210 / 100%),0px 1px 1px 0px rgb(25 118 210 / -10%),0px 1px 3px 0px rgba(0,0,0,0.12)"
            : !error ?  "" :
              "0px 0px 5px 1px rgb(255 0 0 / 100%),0px 1px 1px 0px rgb(255 0 0 / -10%),0px 1px 3px 0px rgba(0,0,0,0.12)",
        cursor: "pointer",
        m: 2,
        textAlign: "center",
      }}
    >
      <CardHeader title={`💬 ${title} sms`}></CardHeader>
      <CardContent>
        <p>
          Mottagaren får ett sms {frequency} tills {title} sms har skickats.
        </p>
        <Typography sx={{ color, fontWeight: 800, fontSize: "2rem" }}>
          {price}
          <span style={{ fontSize: "1rem", color: "#000" }}>kr</span>
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Radio checked={selectedCard === id} />
      </CardActions>
    </Card>
  );
}

export default SelectableCard;
