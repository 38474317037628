import { Controller, FormProvider, useForm } from "react-hook-form";
import TeamSelect from "./TeamSelect";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import SelectableCard from "./SelectableCard";
import { frequencyValues } from "../consts/form";
import Terms, { TermsEnum } from "./Terms";
import PhoneInput from "./PhoneInput";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DiscountCode from "./DiscountCode";

enum Status {
  PENDING,
  LOADING,
  INITIALIZED,
  SUCCESS,
  CANCELED,
}

function CheckoutForm() {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [swishToken, setSwishToken] = useState("");
  const [swishId, setSwishId] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState<"percentage" | "fixed">(
    "fixed"
  );
  const maxAttempts = 120;
  const intervalIdRef = useRef<null | NodeJS.Timeout>(null);
  const attemptsRef = useRef(0);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const methods = useForm();
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState<any>(null);
  const [numberOfWins, setNumberOfWins] = useState(0);

  const verifyMatches = async (data: any) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/count-wins`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          sender_team_id: data.sender_team_id,
          receiver_team_id: data.receiver_team_id,
        }),
      }
    );
    const result = await response.json();
    setNumberOfWins(result.count);
    return result.count >= data.number_of_messages;
  };

  const handlePlaceOrder = async (data: any) => {
    await fetch(`${process.env.REACT_APP_API_URL}/place-order`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ ...data }),
    })
      .then((response) => response.json())
      .then(async ({ image, swish_payment_request, token }) => {
        setStatus(Status.INITIALIZED);
        setQrCode(image);
        setSwishToken(token);
        setModalOpen(true);
        setSwishId(swish_payment_request);
      });
  };

  const onSubmit = async (data: any) => {
    if (data.number_of_messages) {
      setStatus(Status.LOADING);
    }
    if (!data.number_of_messages) {
      methods.setError("number_of_messages", {
        type: "manual",
        message: "Please select the number of messages.",
      });
    } else {
      methods.clearErrors("number_of_messages");

      const hasEnoughMatches = await verifyMatches(data);
      if (!hasEnoughMatches) {
        setOrderData(data);
        setConfirmationOpen(true);
        setStatus(Status.PENDING);
        return;
      }

      await handlePlaceOrder(data);
    }
  };

  const applyDiscount = (
    discountAmount: number,
    type: "percentage" | "fixed"
  ) => {
    setDiscountType(type);
    setDiscount(discountAmount);
  };

  const calculatePrice = (basePrice: number) => {
    if (discountType === "percentage") {
      return basePrice * (1 - discount / 100);
    } else if (discountType === "fixed") {
      return basePrice - discount;
    }
    return basePrice;
  };

  useEffect(() => {
    if (status === Status.INITIALIZED && swishId) {
      intervalIdRef.current = setInterval(() => {
        attemptsRef.current += 1; // Increment attempts using useRef
        fetch(`${process.env.REACT_APP_API_URL}/check-swish/${swishId}`)
          .then((data) => data.json())
          .then((status) => {
            if (status === "PAID") {
              setStatus(Status.SUCCESS);
              clearInterval(intervalIdRef.current!);
            } else if (attemptsRef.current >= maxAttempts) {
              setStatus(Status.CANCELED);
              clearInterval(intervalIdRef.current!);
              setModalOpen(false);
            }
          })
          .catch((error) => {
            console.error('Error checking payment status:', error);
            clearInterval(intervalIdRef.current!);
            setStatus(Status.CANCELED);
            setModalOpen(false);
          });
      }, 1000); // Interval set to 1 second
    }

    if (status === Status.SUCCESS) {
      clearInterval(intervalIdRef.current!);
      navigate(`/confirmation?swish_ref=${swishId}`);
    }

    if (status === Status.CANCELED) {
      clearInterval(intervalIdRef.current!);
      // Optionally, you can handle the cancellation case here, e.g., show a message to the user
    }

    // Cleanup function to clear interval if component unmounts or status changes
    return () => clearInterval(intervalIdRef.current!);
  }, [navigate, status, swishId]);

  return (
    <FormProvider {...methods}>
      <form
        id="form"
        style={{ paddingTop: "3rem" }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <h1>Beställ SMS</h1>
        <TeamSelect label="Vinnande lag" name="sender_team_id" />
        <TeamSelect label="Förlorande lag" name="receiver_team_id" />
        <FormControl sx={{ my: 2 }} fullWidth>
          <InputLabel>Sorteringsordning</InputLabel>
          <Controller
            name="sort_order"
            control={methods.control}
            defaultValue="random"
            render={({ field }) => (
              <Select {...field} label="Sorteringsordning">
                <MenuItem value="asc">Äldst segrar först</MenuItem>
                <MenuItem value="desc">Nyast segrar först</MenuItem>
                <MenuItem value="random">Slumpmässig</MenuItem>
              </Select>
            )}
          />
          <FormHelperText>Välj sorteringsordning för matcherna</FormHelperText>
        </FormControl>
        <PhoneInput name="phone_number" />
        <FormControl sx={{ my: 2 }} fullWidth>
          <InputLabel>Frekvens</InputLabel>
          <Controller
            name="frequency"
            control={methods.control}
            defaultValue={60}
            render={({ field }) => (
              <Select {...field} label="Frekvens">
                {frequencyValues.map(({ value, label }) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormHelperText>Hur ofta ska SMS:en skickas</FormHelperText>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <SelectableCard
            id={10}
            title="10"
            price={calculatePrice(30)}
            color="#1DB45A"
          />
          <SelectableCard
            id={20}
            title="20"
            price={calculatePrice(40)}
            color="#1976d2"
          />
          <SelectableCard
            id={30}
            title="30"
            price={calculatePrice(50)}
            color="#1976d2"
          />
        </Box>
        <Typography variant="caption">Alla priser inklusive moms</Typography>
        <DiscountCode applyDiscount={applyDiscount} />
        <Box height={50} />
        <Typography variant="caption">
          Genom att använda vår tjänst godkänner du våra{" "}
          <Terms terms={TermsEnum.USER} />
          {", "}
          <Terms terms={TermsEnum.PURCHASE} />
          {" och "}
          vår <Terms terms={TermsEnum.PRIVACY} />
        </Typography>
        <Box height={20} />
        <Button
          type="submit"
          disabled={status !== Status.PENDING && status !== Status.CANCELED}
          variant="contained"
        >
          Börja skicka SMS
        </Button>
      </form>
      <Dialog onClose={() => setModalOpen(false)} open={modalOpen}>
        <div
          style={{ padding: "1em", display: "flex", flexDirection: "column" }}
        >
          <img src={`data:image/png;base64,${qrCode}`} alt="Swish QR kod" />
          <Button
            sx={{ mt: 2 }}
            color="primary"
            variant="contained"
            href={`swish://paymentrequest?token=${swishToken}&callbackurl=`}
          >
            Öppna Swish
          </Button>
        </div>
      </Dialog>
      <Dialog
        onClose={() => setConfirmationOpen(false)}
        open={confirmationOpen}
      >
        <DialogTitle>Inte tillräckligt med matcher</DialogTitle>
        <DialogContent>
          {numberOfWins === 0
            ? `Det finns inga vister för valda lag.`
            : `Det finns inte bara ${numberOfWins} vinster för valda lag. Det kommer
          alltså bara skickas ${numberOfWins} SMS. Vill du beställa ändå eller
          välja andra lag/ändra antalet SMS?`}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationOpen(false)} color="primary">
            Välj andra lag
          </Button>
          <Button
            variant="contained"
            disabled={numberOfWins === 0}
            onClick={async () => {
              setConfirmationOpen(false);
              if (orderData) {
                await handlePlaceOrder(orderData);
              }
            }}
            color="primary"
          >
            Beställ ändå
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}

export default CheckoutForm;
