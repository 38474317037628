import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { FortnoxProvider } from '../../context/FortnoxContext';

const AdminLayout: React.FC = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <FortnoxProvider>
      <div>
        <Outlet />
      </div>
    </FortnoxProvider>
  );
};

export default AdminLayout;