import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';

interface FortnoxContextProps {
  token: string | null;
  setToken: (token: string) => void;
}

const FortnoxContext = createContext<FortnoxContextProps | undefined>(undefined);

export const FortnoxProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string | null>(() => {
    return localStorage.getItem('fortnoxToken');
  });

  useEffect(() => {
    if (token) {
      localStorage.setItem('fortnoxToken', token);
    } else {
      localStorage.removeItem('fortnoxToken');
    }
  }, [token]);

  return (
    <FortnoxContext.Provider value={{ token, setToken }}>
      {children}
    </FortnoxContext.Provider>
  );
};

export const useFortnox = (): FortnoxContextProps => {
  const context = useContext(FortnoxContext);
  if (!context) {
    throw new Error('useFortnox must be used within a FortnoxProvider');
  }
  return context;
};