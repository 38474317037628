import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  applyDiscount: (amount: number, type: "percentage" | "fixed") => void;
}

const DiscountCode = ({ applyDiscount }: Props) => {
  const { control } = useFormContext();
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const handleApply = async () => {
    // Replace this with your actual API call to verify the coupon code
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/verify-coupon`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      }
    );
    const result = await response.json();

    if (result.valid) {
      applyDiscount(result.discount, result.discount_type);
      setError("");
    } else {
      applyDiscount(0, "fixed");
      setError("Ogiltig rabattkod");
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="start" my={2}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setIsVisible(!isVisible)}
      >
        {isVisible ? "Dölj rabattkod" : "Ange rabattkod"}
      </Button>
      {isVisible && (
        <>
          <Controller
            name={"coupon_code"}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Rabattkod"
                variant="outlined"
                onChange={(e) => {
                  field.onChange(e);
                  setCode(e.target.value);
                }}
                error={!!error}
                helperText={error}
                sx={{ mt: 2 }}
              />
            )}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleApply}
            sx={{ mt: 2 }}
          >
            Använd rabattkod
          </Button>
        </>
      )}
    </Box>
  );
};

export default DiscountCode;
