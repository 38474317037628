import { Box } from "@mui/material";

const PurchaseTerms = () => (
  <Box p={2} pt={10}>
    <h1>Köp- och leveransvillkor för Finten.se</h1>

    <h2>1. Allmänt</h2>

    <p>
      1.1. Dessa allmänna villkor gäller för alla köp och beställningar gjorda
      på Finten.se. Genom att beställa från Finten.se godkänner du dessa
      villkor.
    </p>
    <p>
      1.2. Tjänsten som erbjuds av Finten.se innebär att du kan beställa ett
      paket med vald mängd SMS som innehåller historiska
      matchresultat från Allsvenskan. Du väljer frekvens och intervall för hur
      ofta SMS:en ska levereras samt mottagarnummer.
    </p>

    <h2>2. Beställning och leverans</h2>

    <p>2.1. Beställningar görs via vår webbplats, Finten.se.</p>
    <p>
      2.2. Efter genomförd beställning visas en orderbekräftelse på en
      landningssida. Ingen orderbekräftelse skickas via e-post.
    </p>
    <p>
      2.3. Leverans av SMS sker enligt det valda paketet och de angivna
      intervallen. Tjänsten påbörjas när betalningen är mottagen.
    </p>

    <h2>3. Priser och betalning</h2>

    <p>3.1. Alla priser på Finten.se anges i svenska kronor inklusive moms.</p>
    <p>
      3.2. Betalning sker genom de betalningsalternativ som anges på
      webbplatsen.
    </p>

    <h2>4. Ångerrätt och retur</h2>

    <p>
      4.1. Enligt distans- och hemförsäljningslagen har du som konsument rätt
      att ångra ditt köp inom 14 dagar från mottagandet av orderbekräftelsen.
    </p>
    <p>
      4.2. Ångerrätten gäller dock inte för digitala tjänster som har påbörjats
      med ditt samtycke. Eftersom vår tjänst innebär att SMS levereras omgående
      efter beställning och betalning, samtycker du till att ångerrätten upphör
      när tjänsten har påbörjats.
    </p>

    <h2>5. Reklamation och klagomål</h2>

    <p>
      5.1. Om det förekommer fel eller brister i leveransen av våra
      SMS-tjänster, vänligen kontakta oss så snart som möjligt.
    </p>
    <p>
      5.2. Reklamation ska ske inom rimlig tid efter att felet upptäckts. Vid
      reklamation ber vi dig att upplysa oss om vilken typ av fel eller brist
      som föreligger samt tillhandahålla relevant information om beställningen.
    </p>
    <p>
      5.3. Finten.se förbehåller sig rätten att bedöma om en reklamation är
      giltig och vidta åtgärder därefter.
    </p>

    <h2>6. Ansvarsbegränsning</h2>

    <p>
      6.1. Finten.se ansvarar inte för eventuella skador eller olägenheter som
      kan uppstå till följd av användning av våra tjänster.
    </p>
    <p>
      6.2. Finten.se ansvarar inte för eventuella kostnader eller avgifter som
      kan uppstå för mottagaren av SMS:en.
    </p>
    <p>
      6.3. Finten.se förbehåller sig rätten att göra ändringar i tjänsten eller
      att avsluta tjänsten utan förvarning.
    </p>

    <h2>7. Personuppgiftshantering</h2>

    <p>
      7.1. Finten.se hanterar endast de personuppgifter som är absolut
      nödvändiga för att tillhandahålla tjänsten, såsom telefonnummer till
      mottagaren av SMS:en.
    </p>
    <p>7.2. Inga e-postadresser eller andra personuppgifter lagras.</p>
    <p>
      7.3. Personuppgifter hanteras i enlighet med gällande
      dataskyddslagstiftning. Mer information om vår personuppgiftshantering
      finns i vår integritetspolicy.
    </p>

    <h2>8. Kontaktinformation</h2>

    <p>För frågor eller klagomål, vänligen kontakta oss via:</p>

    <ul>
      <li>E-post: kontakt@finten.se</li>
      <li>
        Finten.se
        <br />
        c/o Danex Flaggbutik
        <br />
        Sturegatan 55
        <br />
        50342 Borås
      </li>
      <li>S. Ring Development AB, orgnr: 559477-1700</li>
    </ul>

    <p>Genom att beställa från Finten.se accepterar du dessa villkor.</p>
  </Box>
);

export default PurchaseTerms;
